import React from "react";
import { NextSeo, NextSeoProps } from "next-seo";
import { useRouter } from "next/router";
import { appDomain } from "../../models/__variables";

export const PAGE_NOT_CANONICAL: string[] = [];

export default function NextSeoCustom(props: NextSeoProps) {
   const router = useRouter();

   // Hàm xử lý URL canonical
   const getCanonicalUrl = () => {
      // Lấy đường dẫn hiện tại (bỏ query và hash)
      const currentPath = router.asPath.split(/\?|#/)[0];

      // Kiểm tra nếu đường dẫn thuộc danh sách không cần canonical
      if (PAGE_NOT_CANONICAL.includes(currentPath)) {
         return undefined;
      }

      // Tạo URL canonical từ appDomain và đường dẫn
      let canonicalUrl = `${appDomain}${currentPath}`;

      // Nếu có query `id` và đường dẫn chứa `[findIdea]`, thêm vào canonical
      if (router.query.id && router.pathname.includes("[findIdea")) {
         canonicalUrl += `${canonicalUrl.includes("?") ? "&" : "?"}id=${router.query.id}`;
      }

      // Fallback: nếu không xác định được canonical, trả về undefined
      return canonicalUrl || undefined;
   };

   // Lấy canonical URL
   const canonical = getCanonicalUrl();

   // Kiểm tra môi trường (chỉ index/nofollow nếu là production)
   const isProduction = appDomain === "https://spacet.vn";

   return (
      <NextSeo
         canonical={canonical}
         nofollow={!isProduction} // Không follow liên kết nếu không phải production
         noindex={!isProduction} // Không index nếu không phải production
         {...props} // Truyền thêm các thuộc tính từ props
      />
   );
}

// components/BackToTopButton.tsx
import React, { useState, useEffect } from "react";

const BackToTopButton: React.FC = () => {
   const [isVisible, setIsVisible] = useState<boolean>(false);

   // Show button when page is scrolled down
   const toggleVisibility = () => {
      if (window.scrollY > 300) {
         setIsVisible(true);
      } else {
         setIsVisible(false);
      }
   };

   // Scroll to the top smoothly
   const scrollToTop = () => {
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
   };

   useEffect(() => {
      window.addEventListener("scroll", toggleVisibility);
      return () => {
         window.removeEventListener("scroll", toggleVisibility);
      };
   }, []);

   return (
      <>
         {isVisible && (
            <button
               onClick={scrollToTop}
               style={{
                  position: "fixed",
                  bottom: "50px",
                  right: "50px",
                  background: "#ffffff",
                  border: "1px solid #cccccc",
                  borderRadius: "50%",
                  width: "50px",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  cursor: "pointer",
               }}
               aria-label="Back to top"
            >
               ↑
            </button>
         )}
      </>
   );
};

export default BackToTopButton;

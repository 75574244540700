import Cookie from "cookie";
import { CorporateContactJsonLd } from "next-seo";
import App, { AppContext } from "next/app";
import Script from "next/script";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";

// Components
import { useRouter } from "next/router";
import { I18nextProvider } from "react-i18next";
import { AppStoreContextWrapper } from "@Components/AppStoreContext";
import { WrapLayout, DefaultLayout } from "@Components/layout";
import NextSeoCustom from "@Components/nextSeo";
import store from "../state";
import i18n from "../translation/i18n";
import seo from "../libs/validation/seo";
import { HttpWrapper } from "../libs/http";
import { getProfile, verify } from "../state/action/auth";
import { abTesting } from "../state/action/abTest";
import { utmParams } from "../state/action/utmParams";
import { getSocketCollection } from "../service/socketIo";
import useScrollRestoration from "../utils/useScrollRestoration";
import { ABTest, EABTestType } from "../models/abTest.model";
import { ModelError } from "../models/model";
import Head from "next/head";

import "styles/index.scss";
import globalStore from "server/globalStore";
import Menu from "models/menu.model";
import MenuItem from "models/menuItem.model";
import { useInitialRecaptcha } from "helpers/GoogleRecaptcha";
import ModalError from "@Components/modal/ModalError";
import { useFirebaseMessaging } from "service/firebase";
import { handleRedirectToSite } from "utils/handleRedirectToSite";
import GlobalLoading from "@Components/Loading/GlobalLoading";
import { ModalAuth } from "@Components/modal/auth";
import FixedDownloadApp from "@Components/FixedDownloadApp";
import { getDevices } from "utils/SSR/index.handler";
import EmptyLayout from "@Components/layout/EmptyLayout";
import NextScriptCustom from "@Components/NextScriptCustom";
import { GlobalComponentProps } from "models/types";
import { roboto } from "utils/fonts";
import { isIOS } from "react-device-detect";
import FacebookLoginMobileHandler from "@Components/modal/auth/FacebookLoginMobileHandler";
import StickyRightButton from "@Components/StickyRightButton";
import BackToTopButton from "@Components/BackToTopButton";

// import ScriptLazy from "utils/Script";

// if (!isDev) {
//    console.time = () => null
//    console.timeLog = () => null
// }
// declare global {
//    interface Window {
//       ReactNativeWebView: any;
//       tidi: any;
//       tidioChatApi: any;
//       gapi: any;
//       fbAsyncInit: () => void;
//       AppleID: any;
//       dataLayer: [];
//       ga: any;
//    }
// }

export interface AppProps {}

function MyApp({ Component, pageProps, userToken, isMobile, pathname, menu, menus, ...rest }: AppProps & GlobalComponentProps & any) {
   const queryClient = new QueryClient();
   const router = useRouter();

   useInitialRecaptcha();
   useFirebaseMessaging();

   useEffect(() => {
      // var notification = new Notification("Notification title", {
      //    icon: "http://cdn.sstatic.net/stackexchange/img/logos/so/so-icon.png",
      //    body: "Hey there! You've been notified!"
      // });
      // notification.onclick = function() {
      //    window.open("http://stackoverflow.com/a/13328397/1269037");
      // };

      // Handle with firebase-messaging-sw.js file
      // Create a Broadcast Channel
      const channel = new BroadcastChannel("notification-clicks");
      // Listen for messages from the Broadcast Channel
      channel.onmessage = function (event) {
         // console.log("receiver event", event);
         if (event.data.type === "notification-click") {
            handleRedirectToSite(event.data.data);
         }
      };

      getABTesting();
      getSocketCollection();
      store.dispatch(verify());
      store.dispatch(getProfile());

      // Clean up the Broadcast Channel when the component is unmounted
      return () => {
         channel.close();
      };
   }, []);

   useEffect(() => {
      if (typeof window !== "undefined") {
         // if (history.scrollRestoration) {
         //   history.scrollRestoration = 'manual';
         // }

         const urlParams = new URLSearchParams(router.query as any);
         // const get_consult = !urlParams.get("get_consult") ? "" : (urlParams.get("get_consult") as string);
         // const query = get_consult.split("?")[1];
         // const queryObject = {};
         // if (query) {
         //    const queryPairs = query.split("&");
         //    for (let i = 0; i < queryPairs.length; i++) {
         //       const pair = queryPairs[i].split("=");
         //       queryObject[pair[0]] = pair[1];
         //    }
         // }

         store.dispatch(
            utmParams({
               // get_consult: get_consult.split("?")[0],
               get_consult: !urlParams.get("get_consult") ? "" : (urlParams.get("get_consult") as string),
               utm_campaign: !urlParams.get("utm_campaign") ? "" : (urlParams.get("utm_campaign") as string),
               utm_source: !urlParams.get("utm_source") ? "" : (urlParams.get("utm_source") as string),
               utm_medium: !urlParams.get("utm_medium") ? "" : (urlParams.get("utm_medium") as string),
               utm_content: !urlParams.get("utm_content") ? "" : (urlParams.get("utm_content") as string),
               utm_term: !urlParams.get("utm_term") ? "" : (urlParams.get("utm_term") as string),
               // ...queryObject,
            })
         );
      }
   }, [router.query]);
   useScrollRestoration(router);

   const [loading, setLoading] = useState(false);
   useEffect(() => {
      const handleWindowLoaded = () => {
         const windowLoadingEl: HTMLElement = document.getElementById("window_loading")!;
         if (windowLoadingEl) {
            windowLoadingEl.classList.remove("window-loading_loading");
         }
      };

      if (window) {
         window.addEventListener("load", handleWindowLoaded);
      }

      return () => {
         window.removeEventListener("load", handleWindowLoaded);
      };
   }, []);
   useEffect(() => {
      (function () {
         function onTidioChatApiReady() {
            window.tidioChatApi.hide();
            window.tidi.on("close", function () {
               window.tidioChatApi.hide();
            });
            const urlParams = new URLSearchParams(window.location.search);
            const utms = {
               ...(!urlParams.get("utm_campaign") ? {} : { utm_campaign: urlParams.get("utm_campaign") }),
               ...(!urlParams.get("utm_source") ? {} : { utm_source: urlParams.get("utm_source") }),
               ...(!urlParams.get("utm_medium") ? {} : { utm_medium: urlParams.get("utm_medium") }),
               ...(!urlParams.get("utm_content") ? {} : { utm_content: urlParams.get("utm_content") }),
               ...(!urlParams.get("utm_term") ? {} : { utm_term: urlParams.get("utm_term") }),
               ...(!urlParams.get("spacet_user_name") ? {} : { spacet_user_name: urlParams.get("spacet_user_name") }),
            };
            if (utms.spacet_user_name) {
               window.tidioChatApi.setVisitorData({
                  name: `${utms.spacet_user_name} %REACT_APP_SUFFIX_TIDIO%`.trim(),
               });
            }
            window.tidioChatApi.setContactProperties({
               ...utms,
            });
         }
         if (window.tidioChatApi) {
            window.tidioChatApi.on("ready", onTidioChatApiReady);
         } else {
            document.addEventListener("tidioChat-ready", onTidioChatApiReady);
         }
      })();
   }, []);

   const getABTesting = async () => {
      const abTest = await ABTest.abTest({ key: EABTestType.consulting });
      if (abTest instanceof ModelError) {
         return;
      }
      store.dispatch(abTesting({ [EABTestType.consulting]: abTest }));
      // store.dispatch(abTesting({ [EABTestType.consulting]: EABConsultingForm.A }));
   };
   const handleHttpError = useCallback((error: Error) => {
      // eslint-disable-next-line no-console
      console.error("handleHttpError", error);
   }, []);

   if (Component.pure) {
      return <Component {...rest.pageProps} />;
   }

   // Use the layout defined at the page level, if available
   const Layout = Component.mp ? EmptyLayout : Component.Layout || DefaultLayout;

   return (
      <Fragment>
         <Head>
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="viewport" content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1" />
            <meta name="copyright" content="Space T" />
            <meta name="theme-color" content="#ff6347" />
            <meta name="author" content="Space T" />
            <meta name="distribution" content="Global" />
            <meta name="format-detection" content="telephone=no" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="https://spacet.vn/" />

            <meta property="og:locale" content="vi_VN" />
            <meta property="og:type" content="article" />

            <meta name="p:domain_verify" content="90050ff874ff33d4b01167d0cd373e20" />

            <link rel="manifes" href="/manifest.json" />

            <meta name="google-signin-client_id" content="1005844769087-k6u9eno9fh9bc7tfh00vg95677urcml6.apps.googleusercontent.com" />
            <meta name="facebook-domain-verification" content="mk40ahm9e1unxi2lw4ovwjz1n7k2p3" />

            <link rel="apple-touch-icon" href="/assets/favicon.png?v=1.0"></link>
            <link rel="icon" href="/assets/favicon.png?v=1.0" type="image/gif" sizes="16x16" />
         </Head>
         <Provider store={store}>
            <style jsx global>{`
               html,
               body {
                  font-family: ${roboto.style.fontFamily};
               }
            `}</style>
            <I18nextProvider i18n={i18n}>
               {/* <ScriptLazy /> */}
               <NextSeoCustom {...seo.basic(pathname, pageProps)} />
               <CorporateContactJsonLd {...seo.jsonLD.contact} />
               <Script id="jsonLD" defer type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(seo.jsonLD.website) }} />

               <NextScriptCustom />
               {isMobile && isIOS && <FacebookLoginMobileHandler router={router} />}

               <HttpWrapper token={userToken} onError={handleHttpError}>
                  <AppStoreContextWrapper loading={loading}>
                     <QueryClientProvider client={queryClient}>
                        <WrapLayout>
                           <Layout {...pageProps} menu={menu} menus={menus} userToken={userToken} router={router} isMobile={isMobile}>
                              <Component {...pageProps} menu={menu} menus={menus} router={router} isMobile={isMobile} />
                           </Layout>
                           <GlobalLoading />
                           <ModalError />
                           <ModalAuth />
                           {isMobile && <FixedDownloadApp />}
                           {(router.pathname === "/" ||
                              router.pathname.startsWith("/shop") ||
                              router.pathname.startsWith("/quy-trinh-ket-noi") ||
                              router.pathname.startsWith("/bao-gia") ||
                              router.pathname.startsWith("/blog") ||
                              router.pathname.startsWith("/cac-cong-ty-noi-that") ||
                              router.pathname.startsWith("/cong-trinh-thuc-te") ||
                              router.pathname.startsWith("/noithatgiasi") ||
                              router.pathname.startsWith("/referral-program") ||
                              router.pathname.startsWith("/thiet-ke-2d-3d") ||
                              router.pathname.startsWith("/thu-vien-anh") ||
                              router.pathname.startsWith("/thu-vien-thiet-ke") ||
                              router.pathname.startsWith("/to-am-cua-toi")) && <StickyRightButton router={router} isMobile={isMobile} />}
                        </WrapLayout>
                     </QueryClientProvider>
                  </AppStoreContextWrapper>
               </HttpWrapper>
            </I18nextProvider>
         </Provider>
         {!isMobile && <BackToTopButton />}
      </Fragment>
   );
}

export default MyApp;

const getCookieToken = (appContext: AppContext) => {
   const cookies = Cookie.parse(appContext.ctx?.req?.headers?.cookie || "");
   return cookies.userToken || null;
};
MyApp.getInitialProps = async (appContext: AppContext) => {
   const appProps = await App.getInitialProps(appContext);
   const userToken = getCookieToken(appContext);
   const pathname = appContext.ctx.asPath || appContext.ctx.pathname;

   // const isFetchFullMenu = !/^\/(feed(.*))$/.test(pathname) && pathname !== "/";

   const menus = await Menu.getMenuList();

   const levelOne: MenuItem[] = JSON.parse(JSON.stringify(menus.__items));
   levelOne.forEach((item) => {
      if (!item.info.slug) {
         item.info.slug = "quy-trinh-ket-noi";
      }
      if (item.__childs && item.__childs.length > 0) {
         item.__childs.forEach((child: any) => {
            if (child.__childs) delete child.__childs;
         });
      }
   });

   const levelTwo = {};
   menus.__items?.forEach((parent) => {
      levelTwo[parent.info.slug || ""] = parent.__childs;
   });

   const cloneMenus = JSON.parse(
      JSON.stringify({
         levelOne,
         levelTwo,
      })
   );

   globalStore.set("menus", cloneMenus);
   globalStore.set("initProps", {
      url: appContext.ctx?.req?.url,
      query: appContext.ctx.query,
      asPath: appContext.ctx.asPath,
      pathname: appContext.ctx.pathname,
   });

   const { isMobile } = getDevices(appContext.ctx.req);
   return {
      ...appProps,
      isMobile,
      userToken,
      pathname,
      menu: cloneMenus,
      menus,
   };
};

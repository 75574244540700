/* eslint-disable max-len */
import { cloudFrontResize } from "@Config/__env";

export function resizeImage(id, info: any = {}, isMigrated) {
   let obj: {
      $url?: string;
      $url64ResizeMax?: string;
      $url256ResizeMax?: string;
      $url384ResizeMax?: string;
      $url768ResizeMax?: string;
      $url1024ResizeMax?: string;
   } = {};
   let filePath;
   if (isMigrated) {
      filePath = info.originalName || "";
   } else {
      // edit for social feed when is not migrated
      // some thing no info.extension but somthing is required info.extension. so we donot need check exit extion
      filePath = id ? `${id}${info.extension}` : "";
   }
   if (filePath && info.protocol && info.domain && info.container) {
      obj.$url = `${info.protocol}://${info.domain}/${info.container}/${filePath}`;
      // eslint-disable-next-line max-len
      obj.$url64ResizeMax = `${cloudFrontResize}/image-resized/64x64_max/${info.container}/${filePath}`;
      obj.$url256ResizeMax = `${cloudFrontResize}/image-resized/256x2560_max/${info.container}/${filePath}`;
      obj.$url384ResizeMax = `${cloudFrontResize}/image-resized/384x3840_max/${info.container}/${filePath}`;
      obj.$url768ResizeMax = `${cloudFrontResize}/image-resized/768x7680_max/${info.container}/${filePath}`;
      obj.$url1024ResizeMax = `${cloudFrontResize}/image-resized/1024x10240_max/${info.container}/${filePath}`;
   }
   return obj;
}

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";
import firebaseConfig, { cacheFirebaseLocalToken } from "../config/firebase";
import { isClient } from "utils";

let app, messaging, db;

export const useFirebaseMessaging = () => {};

if (isClient) {
   isSupported()
      .then((status) => {
         if (status) {
            app = initializeApp(firebaseConfig);
            messaging = getMessaging(app);
            getToken(messaging, { vapidKey: firebaseConfig.FIREBASE_USEPUBLICVAPIDKEY })
               .then((currentToken) => {
                  if (currentToken) {
                     requestPermission();
                     // // console.log("currentToken", currentToken);
                     localStorage.setItem(cacheFirebaseLocalToken, currentToken);
                  } else {
                     // console.log("No registration token available. Request permission to generate one.");
                  }
               })
               .catch((err) => {
                  // console.log("An error occurred while retrieving token. ", err);
               });
         }
      })
      .catch((e) => {
         console.error("Firebase is not supported", e);
      });

   function requestPermission() {
      // console.log("Requesting permission...");
      Notification.requestPermission().then((permission) => {
         if (permission === "granted") {
            // console.log("Notification permission granted.");
         }
      });
   }
}
